import React from 'react'

const CoursesData = () => {
  return (
    <>
    <div className="container md:mt-28 mt-24">
    <div className="grid md:grid-cols-12 grid-cols-1 items-center">
      <div className="md:col-span-6">
        <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Latest Insights</h6>
        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Our Courses</h3>
      </div>
      <div className="md:col-span-6">
        <p className="text-slate-400 max-w-xl">You’re in the business of connectivity. Your customers expect network reliability and demand a first-rate customer experience.</p>
      </div>
    </div>{/*end grid*/}
    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">
      <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
        <img src="assets/images/blog/01.jpg" alt="" />
        <div className="content p-6">
          <a href="blog-detail.html" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Angular / UI / Frontend Developer</a>
          <p className="text-slate-400 mt-3">Overview Curriculum Features Overview An Azure Administrator is responsible for implementing, monitoring, and maintaining</p>
          <div className="mt-4">
            <a href="#" className="btn btn-link font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right" /></a>
          </div>
        </div>
      </div>
      <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
        <img src="assets/images/blog/02.jpg" alt="" />
        <div className="content p-6">
          <a href="blog-detail.html" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">NodeJS / Express / Mango DB Server</a>
          <p className="text-slate-400 mt-3">Overview Curriculum Features Overview This course covers everything server-side. The entire course is built</p>
          <div className="mt-4">
            <a href="#" className="btn btn-link font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right" /></a>
          </div>
        </div>
      </div>
      <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
        <img src="assets/images/blog/03.jpg" alt="" />
        <div className="content p-6">
          <a href="blog-detail.html" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Python Developer</a>
          <p className="text-slate-400 mt-3">Overview Curriculum Features Overview Web development, machine learning, AI, scientific computing, and academic research</p>
          <div className="mt-4">
            <a href="#" className="btn btn-link font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right" /></a>
          </div>
        </div>
      </div>
      <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
        <img src="assets/images/blog/03.jpg" alt="" />
        <div className="content p-6">
          <a href="blog-detail.html" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Azure Administrator</a>
          <p className="text-slate-400 mt-3">Overview Curriculum Features Overview An Azure Administrator is responsible for implementing, monitoring, and maintaining Microsoft Azure</p>
          <div className="mt-4">
            <a href="#" className="btn btn-link font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right" /></a>
          </div>
        </div>
      </div>
      <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
        <img src="assets/images/blog/03.jpg" alt="" />
        <div className="content p-6">
          <a href="blog-detail.html" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">MS SQL Server & BI</a>
          <p className="text-slate-400 mt-3">Overview Curriculum Features Overview Microsoft SQL Server and is a relational database management system with the</p>
          <div className="mt-4">
            <a href="#" className="btn btn-link font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right" /></a>
          </div>
        </div>
      </div>
      <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
        <img src="assets/images/blog/03.jpg" alt="" />
        <div className="content p-6">
          <a href="blog-detail.html" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Clinical Data Management (CDM)</a>
          <p className="text-slate-400 mt-3">Overview Curriculum Features Overview The clinical data manager plays a key role in the setup and</p>
          <div className="mt-4">
            <a href="#" className="btn btn-link font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right" /></a>
          </div>
        </div>
      </div>

    </div>{/*end grid*/}
  </div>{/*end container*/}
  </>
  )
}

export default CoursesData
