import React, {useEffect} from 'react'
import Courses from '../components/CoursesData'

import { Link } from "react-router-dom";
import ServicesList from '../components/ServicesList';

const Services = () => {
  useEffect(() => {
    window.scrollTo(0,0);
}, [])
  return (
    <>
       <section className="relative table w-full py-20 lg:py-20  bg-no-repeat bg-center">
        <div className="absolute inset-0 bg-black opacity-75" />
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
           
            <h3 className="mt-2 md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">Our Services</h3>
          </div>{/*end grid*/}
        </div>{/*end container*/}
        <div className="absolute text-center z-10 bottom-5 right-0 left-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Home</Link></li>
           
            <li className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Services</li>
          </ul>
        </div>
      </section>
       
      <div className="relative">
        <div className="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <ServicesList/>
        <div className="container bg-gray-50 md:py-24 py-16 mt-24">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
          <div className="flex">
            <div className="flex align-middle justify-center items-center min-w-[56px] h-[56px] bg-indigo-600/5 border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-xl shadow-sm dark:shadow-gray-800">
              <i className="uil uil-water" />
            </div>
            <div className="content ltr:ml-6 rtl:mr-6">
              <Link to="#" className="text-lg font-medium hover:text-indigo-600">24/7 Support</Link>
              <p className="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>
            </div>
          </div>
          {/*end feature content*/}
          <div className="flex">
            <div className="flex align-middle justify-center items-center min-w-[56px] h-[56px] bg-indigo-600/5 border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-xl shadow-sm dark:shadow-gray-800">
              <i className="uil uil-dropbox" />
            </div>
            <div className="content ltr:ml-6 rtl:mr-6">
              <Link to="#" className="text-lg font-medium hover:text-indigo-600">Take Ownership</Link>
              <p className="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>
            </div>
          </div>
          {/*end feature content*/}
          <div className="flex">
            <div className="flex align-middle justify-center items-center min-w-[56px] h-[56px] bg-indigo-600/5 border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-xl shadow-sm dark:shadow-gray-800">
              <i className="uil uil-focus-target" />
            </div>
            <div className="content ltr:ml-6 rtl:mr-6">
              <Link to="#" className="text-lg font-medium hover:text-indigo-600">Team Work</Link>
              <p className="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>
            </div>
          </div>
          {/*end feature content*/}
        </div>{/*end grid*/}
      </div>

          <Courses/>
        
        <div className="container md:mt-24 mt-16">
          <div className="grid grid-cols-1 text-center">
            <h6 className="text-indigo-600 text-sm font-bold mb-2">Available for freelance projects</h6>
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Do you have digital project? <br /> Let's talk.</h3>
            <p className="text-slate-400 max-w-xl mx-auto">Start working with Techwind that can provide everything you need to generate awareness, drive traffic, connect.</p>
            <div className="mt-6">
              <a href="#!" className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-4"><i className="uil uil-phone" /> Contact us</a>
            </div>
          </div>{/*end grid*/}
        </div>{/*end container*/}
      </section>



    </>
  )
}

export default Services
