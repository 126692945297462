import React from 'react'

const Footer = () => {
  return (
    <>
      <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">    

<div className="py-[30px] px-0 border-t border-slate-800">
  <div className="container text-center">
    <div className="grid md:grid-cols-1 items-center">
      <div className="ltr:md:text-center rtl:md:text-center text-center">
        <p className="mb-0">© 2023 SG Consulting Group LLC. Design with  by Gyarala Technologies.</p>
      </div>
      {/* <ul className="list-none ltr:md:text-right rtl:md:text-left text-center mt-6 md:mt-0">
        <li className="inline"><a href="#"><img src="assets/images/payments/american-ex.png" className="max-h-6 inline" title="American Express" alt="" /></a></li>
        <li className="inline"><a href="#"><img src="assets/images/payments/discover.png" className="max-h-6 inline" title="Discover" alt="" /></a></li>
        <li className="inline"><a href="#"><img src="assets/images/payments/master-card.png" className="max-h-6 inline" title="Master Card" alt="" /></a></li>
        <li className="inline"><a href="#"><img src="assets/images/payments/paypal.png" className="max-h-6 inline" title="Paypal" alt="" /></a></li>
        <li className="inline"><a href="#"><img src="assets/images/payments/visa.png" className="max-h-6 inline" title="Visa" alt="" /></a></li>
      </ul> */}
    </div>{/*end grid*/}
  </div>{/*end container*/}
</div>
</footer>
    </>
  )
}

export default Footer
