import React from 'react'
import { Link } from 'react-router-dom'

const Contact = () => {
  return (
    <div className="container   bg-gray-50 md:py-24 py-16">
        <div className="grid grid-cols-1 text-center">
          <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Contact Us</h6>
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Have Question ? Get in touch!</h3>
          <p className="text-slate-400 max-w-xl mx-auto">Join SG Consulting Academy – a growing hub of young individuals striving to make a difference at the workplace with a bunch of powerful technologies</p>
          <div className="mt-6">
            <Link to="contact-us" className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-4"><i className="uil uil-phone" /> Contact us</Link>
          </div>
        </div>{/*end grid*/}
      </div>
  )
}

export default Contact
