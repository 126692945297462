import React from 'react'
import { Link } from "react-router-dom";
const ServicesList = () => {
  return (
   <>
    <div className="container">
      <div className="grid grid-cols-1 pb-8 text-center">
        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">What we do ?</h3>
        <p className="text-slate-400 max-w-xl mx-auto">Create, collaborate, and turn your ideas into incredible products with the definitive platform for digital design.</p>
      </div>{/*end grid*/}
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
        <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
          <div className="relative overflow-hidden text-transparent -m-3">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-hexagon h-24 w-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" /></svg>
            <div className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
              <i className="uil uil-chart-line" />
            </div>
          </div>
          <div className="mt-6">
            <Link to="#" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out">E-Verified</Link>
            <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3">Employment eligibility verification and authorization with the simple E-Verify program</p>
          </div>
        </div>{/*end feature*/}
        <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
          <div className="relative overflow-hidden text-transparent -m-3">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-hexagon h-24 w-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" /></svg>
            <div className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
              <i className="uil uil-crosshairs" />
            </div>
          </div>
          <div className="mt-6">
            <Link to="#" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out">On-Project Support</Link>
            <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3">Professionally-driven, real time and concise project assistance by trained experts</p>
          </div>
        </div>{/*end feature*/}
        <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
          <div className="relative overflow-hidden text-transparent -m-3">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-hexagon h-24 w-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" /></svg>
            <div className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
              <i className="uil uil-airplay" />
            </div>
          </div>
          <div className="mt-6">
            <Link to="#" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out">Training & Orientation</Link>
            <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3">Certification-oriented training programs focused on developing core competencies</p>
          </div>
        </div>{/*end feature*/}
        <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
          <div className="relative overflow-hidden text-transparent -m-3">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-hexagon h-24 w-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" /></svg>
            <div className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
              <i className="uil uil-rocket" />
            </div>
          </div>
          <div className="mt-6">
            <Link to="#" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out">3-5 Training Weeks</Link>
            <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3">Comprehensive and career-defining skills covered inside weeks of rigorous training</p>
          </div>
        </div>{/*end feature*/}
        <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
          <div className="relative overflow-hidden text-transparent -m-3">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-hexagon h-24 w-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" /></svg>
            <div className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
              <i className="uil uil-clock" />
            </div>
          </div>
          <div className="mt-6">
            <Link to="#" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out">H1B & Green Card Sponsorship</Link>
            <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3">Value-based advice and professional consulting for aspiring legal immigrants</p>
          </div>
        </div>{/*end feature*/}
        <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
          <div className="relative overflow-hidden text-transparent -m-3">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-hexagon h-24 w-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" /></svg>
            <div className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
              <i className="uil uil-users-alt" />
            </div>
          </div>
          <div className="mt-6">
            <Link to="#" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out">Healthcare, Dental & Vision Plan</Link>
            <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3">Project implementation & staff augmentation for healthcare, dental industries</p>
          </div>
        </div>{/*end feature*/}
        <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
          <div className="relative overflow-hidden text-transparent -m-3">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-hexagon h-24 w-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" /></svg>
            <div className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
              <i className="uil uil-file-alt" />
            </div>
          </div>
          <div className="mt-6">
            <Link to="#" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out">Certified Training Professionals</Link>
            <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3">Maximum control with custom coaching by trained and certified training professionals</p>
          </div>
        </div>{/*end feature*/}
        <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
          <div className="relative overflow-hidden text-transparent -m-3">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-hexagon h-24 w-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" /></svg>
            <div className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
              <i className="uil uil-search" />
            </div>
          </div>
          <div className="mt-6">
            <Link to="#" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out">Placement Within 3 to 6 Weeks</Link>
            <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3">Incredibly high placement rate within the first 4 weeks of attending classes</p>
          </div>
        </div>{/*end feature*/}
      </div>
    </div>{/*end container*/}
    </>
  )
}

export default ServicesList
