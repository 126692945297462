import { BrowserRouter, Routes, Route } from "react-router-dom";
import Courses from "./pages/Courses";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Home from "./pages/Home";
import Services from "./pages/Services";
import { Nofound } from "./pages/Nofound";

const App = () => {
  return (
    <BrowserRouter>
    <NavBar/>
    <Routes>
         <Route path="*" element={<Nofound />} />
        <Route index element={<Home />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="services" element={<Services />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="courses" element={<Courses />} />
    </Routes>
    <Footer/>
  </BrowserRouter>
  )
}

export default App
