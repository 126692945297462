import React, {useEffect} from 'react'
import { Link } from "react-router-dom";
const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0,0);
}, [])
  return (
    <>
          <section className="relative table w-full py-20 bg-center bg-no-repeat">
        <div className="absolute inset-0 bg-black opacity-75" />
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="md:text-4xl text-3xl md:leading-normal tracking-wide leading-normal font-medium text-white">Contact Us</h3>
          </div>{/*end grid*/}
        </div>{/*end container*/}
        <div className="absolute text-center z-10 bottom-5 right-0 left-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Home</Link></li>
            <li className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Contact Us</li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
            <div className="text-center px-6 mt-6">
              <div className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i className="uil uil-phone" />
              </div>
              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">Phone</h5>
                <div className="mt-5">
                  <a href="tel:+19 08 421 3718" className="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">+19 08 421 3718</a>
                </div>
              </div>
            </div>
            <div className="text-center px-6 mt-6">
              <div className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i className="uil uil-envelope" />
              </div>
              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">Email</h5>
                <div className="mt-5">
                  <a href="mailto:sg@sgcgusa.com" className="btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">sg@sgcgusa.com</a>
                </div>
              </div>
            </div>
            <div className="text-center px-6 mt-6">
              <div className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i className="uil uil-map-marker" />
              </div>
              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">Location</h5>
                
                <div className="mt-5">
                  <a href="https://goo.gl/maps/J2VjoVWDpTABxrni6" data-type="iframe" className="video-play-icon read-more lightbox btn btn-link text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">4611 Wells Drive, Parlin NJ - 08859</a>
                </div>
              </div>
            </div>
          </div>{/*end grid*/}
        </div>{/*end container*/}
        <div className="container md:mt-24 mt-16">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7 md:col-span-6">
              <img src="assets/images/contact.svg" alt="" />
            </div>
            <div className="lg:col-span-5 md:col-span-6 mt-8 md:mt-0">
              <div className="ltr:lg:ml-5 rtl:lg:mr-5">
                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                  <h3 className="mb-6 text-2xl leading-normal font-medium">Get in touch !</h3>
                  <form method="post" name="myForm" id="myForm" onsubmit="return validateForm()">
                    <p className="mb-0" id="error-msg" />
                    <div id="simple-msg" />
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-6 mb-5">
                        <div className="ltr:text-left rtl:text-right">
                          <label htmlFor="name" className="font-semibold">Your Name:</label>
                          <div className="form-icon relative mt-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                            <input name="name" id="name" type="text" className="form-input ltr:pl-11 rtl:pr-11" placeholder="Name :" />
                          </div>
                        </div>
                      </div>
                      <div className="lg:col-span-6 mb-5">
                        <div className="ltr:text-left rtl:text-right">
                          <label htmlFor="email" className="font-semibold">Your Email:</label>
                          <div className="form-icon relative mt-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" /><polyline points="22,6 12,13 2,6" /></svg>
                            <input name="email" id="email" type="email" className="form-input ltr:pl-11 rtl:pr-11" placeholder="Email :" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-1">
                      <div className="mb-5">
                        <div className="ltr:text-left rtl:text-right">
                          <label htmlFor="subject" className="font-semibold">Your Question:</label>
                          <div className="form-icon relative mt-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-book w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"><path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20" /><path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z" /></svg>
                            <input name="subject" id="subject" className="form-input ltr:pl-11 rtl:pr-11" placeholder="Subject :" />
                          </div>
                        </div>
                      </div>
                      <div className="mb-5">
                        <div className="ltr:text-left rtl:text-right">
                          <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                          <div className="form-icon relative mt-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-message-circle w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z" /></svg>
                            <textarea name="comments" id="comments" className="form-input ltr:pl-11 rtl:pr-11 h-28" placeholder="Message :" defaultValue={""} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="submit" id="submit" name="send" className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md justify-center flex items-center">Send Message</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>{/*end container*/}
      </section>
    </>
  )
}

export default ContactUs
