import React, {useEffect} from 'react'
import ServiceTestimonials from '../components/ServiceTestimonials'
import { Link } from "react-router-dom";
const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0,0);
}, [])
  return (
    <>
      <section className="relative table w-full py-20 lg:py-20  bg-no-repeat bg-center">
        <div className="absolute inset-0 bg-black opacity-75" />
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="mb-6 md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">About Us</h3>
            
          </div>{/*end grid*/}
        </div>{/*end container*/}
        <div className="absolute text-center z-10 bottom-5 right-0 left-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Home</Link></li>
           
            <li className="inline breadcrumb-item uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Aboutus</li>
          </ul>
        </div>
      </section>

      <div className="relative">
        <div className="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
          </svg>
        </div>
      </div>


      <section className="relative md:py-24 py-16">
        <div className="container">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-5 md:col-span-6">
              <div className="grid grid-cols-12 gap-6 items-center">
                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img src="assets/images/about/ab03.jpg" className="shadow rounded-md" alt="" />
                    <img src="assets/images/about/ab02.jpg" className="shadow rounded-md" alt="" />
                  </div>
                </div>
                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img src="assets/images/about/ab01.jpg" className="shadow rounded-md" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-span-7 md:col-span-6">
              <div className="ltr:lg:ml-5 rtl:lg:mr-5">
                <div className="flex mb-4">
                  <span className="text-indigo-600 text-2xl font-bold mb-0"><span className="counter-value text-6xl font-bold" data-target={10}>10</span>+</span>
                  <span className="self-end font-medium ltr:ml-2 rtl:mr-2">Years <br /> Experience</span>
                </div>
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Who we are ?</h3>
                 <p className="text-slate-400 max-w-xl">The future—and how we get there—depends on innovators who build, connect, create and transform our world through technology. We’re fully engaged, hands-on collaborators, rooted in core values and passionate about accelerating positive change for our customers, our people and our communities.</p>
                <p className="text-slate-400 max-w-xl">A new world of opportunity awaits. We stand ready to partner side by side with you. Whether it’s developing solutions that transform your technology stack, your business or your workforce, we’re in it with you.</p>
               <div className="mt-6">
                  <a href="#" className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md ltr:mr-2 rtl:ml-2 mt-2"><i className="uil uil-envelope" /> Contact us</a>
                </div>
              </div>
            </div>
          </div>{/*end grid*/}
        </div>{/*end container*/}
        {/* Business Partner */}
        <div className='container mt-16'>
        <div class="grid md:grid-cols-12 grid-cols-1 items-center"><div class="md:col-span-6"><h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">Our Partnerships</h6><h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">In Good Company</h3><p class="text-slate-400 max-w-xl">Transformational technologies demand equally transformative partnerships. Full-stack capabilities coupled with depth and diversity of experience in leading platforms that help organizations grow, innovate and thrive.</p></div><div class="md:col-span-6"><div className="grid md:grid-cols-3 grid-cols-2 justify-center gap-[30px]">
            <div className="mx-auto mt-8">
              <img src="assets/images/salsf.png" className="h-14" alt="" />
            </div>
            <div className="mx-auto mt-8">
              <img src="assets/images/am.png" className="h-14" alt="" />
            </div>
            <div className="mx-auto mt-8">
              <img src="assets/images/ibm.png" className="h-14" alt="" />
            </div>
           
          </div>{/*end grid*/}</div></div></div>
        
      </section>

      
    </>
  )
}

export default AboutUs
