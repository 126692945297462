import React,{useEffect}  from 'react'
import { Link } from "react-router-dom";
import Contact from '../components/Contact';
import Courses from '../components/CoursesData';
import ServicesList from '../components/ServicesList';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0,0);
}, [])
  return (
    <div>
        <section className="relative table w-full py-36 pb-0 lg:py-44 lg:pb-0 bg-indigo-600  bg-center bg-no-repeat">
        <div className="container">
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7">
              <div className="ltr:md:mr-6 rtl:md:ml-6 md:mb-20">
                <h5 className="text-lg text-white/60">We are creative</h5>
                <h4 className="font-semibold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-white">Accelerating Business <br />  Transformation</h4>
                <p className="text-white/60 text-lg max-w-xl">SG Consulting identifies technologies of the future and embeds them into your Digital Transformation Strategy</p>
                <div className="mt-6">
                  <Link to="about-us" className="btn bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700 rounded-md ltr:mr-2 rtl:ml-2 mt-2"><i className="uil uil-envelope" /> Get Started</Link>
                </div>
              </div>
            </div>{/*end col*/}
            <div className="lg:col-span-5 mt-8 md:mt-0">
              <img src="assets/images/hero1.png" alt="" />
            </div>{/*end col*/}
          </div>{/*end grid*/}
        </div>{/*end container*/}
      </section>

      <section className="relative md:py-24 py-16">
        <ServicesList/>
        <div className="container md:mt-24 mt-16">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-5 md:col-span-6">
              <div className="grid grid-cols-12 gap-6 items-center">
                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img src="assets/images/about/ab03.jpg" className="shadow rounded-md" alt="" />
                    <img src="assets/images/about/ab02.jpg" className="shadow rounded-md" alt="" />
                  </div>
                </div>
                <div className="col-span-6">
                  <div className="grid grid-cols-1 gap-6">
                    <img src="assets/images/about/ab01.jpg" className="shadow rounded-md" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-span-7 md:col-span-6">
              <div className="ltr:lg:ml-5 rtl:lg:mr-5">
                <div className="flex mb-4">
                  <span className="text-indigo-600 text-2xl font-bold mb-0"><span className="counter-value text-6xl font-bold" data-target={10}>10</span>+</span>
                  <span className="self-end font-medium ltr:ml-2 rtl:mr-2">Years <br /> Experience</span>
                </div>
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Who we are ?</h3>
                <p className="text-slate-400 max-w-xl">The future—and how we get there—depends on innovators who build, connect, create and transform our world through technology. We’re fully engaged, hands-on collaborators, rooted in core values and passionate about accelerating positive change for our customers, our people and our communities.</p>
                <p className="text-slate-400 max-w-xl">A new world of opportunity awaits. We stand ready to partner side by side with you. Whether it’s developing solutions that transform your technology stack, your business or your workforce, we’re in it with you.</p>
                <div className="mt-6">
                  <Link to="about-us" className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md ltr:mr-2 rtl:ml-2 mt-2"><i className="uil uil-envelope" /> Let's Go</Link>
                </div>
              </div>
            </div>
          </div>{/*end grid*/}
        </div>{/*end container*/}
        <div className="container md:mt-24 mt-16">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="md:col-span-6 order-1 md:order-2">
              <div className="ltr:lg:ml-8 rtl:lg:mr-8">
                <img src="assets/images/shape-image.png" alt="" />
              </div>
            </div>
            <div className="md:col-span-6 order-2 md:order-1">
              <div className="ltr:lg:mr-5 rtl:lg:ml-5">
                <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Success Story</h6>
                <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Leaving a Legacy</h3>
                <p className="text-slate-400 max-w-xl mb-6">Ventech Solutions improves performance and lower costs via ServiceNow, fostering business agility with Scaled Agile (SAFe®) training..</p>
                <Link to="about-us" className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"><i className="uil uil-notes" /> Read Now</Link>
              </div>
            </div>
          </div>
        </div>{/*end container*/}
        <div className="container md:mt-24 mt-16">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[100px]">
            <div className="md:col-span-6 order-2 md:order-1">
              <div className="ltr:lg:ml-8 rtl:lg:mr-8">
                <img src="assets/images/shape-image.png" alt="" />
              </div>
            </div>
            <div className="md:col-span-6 order-1 md:order-2">
              <div className="ltr:lg:mr-5 rtl:lg:ml-5">
                <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Success Story</h6>
                <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">A Thrilling Partnership</h3>
                <p className="text-slate-400 max-w-xl mb-6">Theme park builds an app to enhance the guest experience and raise the bar of what's possible.</p>
                <Link to="about-us" className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"><i className="uil uil-notes" /> Read Now</Link>
              </div>
            </div>
          </div>
        </div>{/*end container*/}
      </section>

      <section className="py-20 w-full table relative bg-gray-600 bg-center bg-no-repeat">
        <div className="absolute inset-0 bg-black/70" />
        <div className="container relative">
          <div className="grid grid-cols-1 text-center">
            <h3 className="mb-4 md:text-3xl text-2xl text-white font-medium">In Good Company</h3>
            <p className="text-white/70 max-w-xl mx-auto">Transformational technologies demand equally transformative partnerships. Full-stack capabilities coupled with depth and diversity of experience in leading platforms that help organizations grow, innovate and thrive.</p>
            
          </div>{/*end grid*/}
        </div>{/*end container*/}
      </section>
      
      <section className="relative md:pb-24 pb-16">
        
        <Courses/>
        
      </section>
     
      <Contact/>

      
      <a href="#" onclick="topFunction()" id="back-to-top" className="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 ltr:right-5 rtl:left-5 h-9 w-9 text-center bg-indigo-600 text-white leading-9"><i className="uil uil-arrow-up" /></a>
     
    </div>

    
  )
}

export default Home

